import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Firstparty.js lets you measure activity on your website with a simple set of Javascript functions.`}</p>
    <div className="alert alert-info">Firstparty.js is based on the incredible open-source Analytics.js from our friends
at Segment.</div>
    <h2 {...{
      "id": "installing-firstpartyjs",
      "style": {
        "position": "relative"
      }
    }}>{`Installing Firstparty.js`}<a parentName="h2" {...{
        "href": "#installing-firstpartyjs",
        "aria-label": "installing firstpartyjs permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`In general, you should follow the installation instructions shown when you create your Javascript Source in Firstparty.`}</p>
    <p>{`The Firstparty.js snippet loads the Firstparty.js library and configures the library to send collected `}<a parentName="p" {...{
        "href": "/docs/items/events/"
      }}>{`Events`}</a>{` to the
Source you've specified via the domain you've configured in Firstparty.`}</p>
    <h2 {...{
      "id": "measurement-methods",
      "style": {
        "position": "relative"
      }
    }}>{`Measurement Methods`}<a parentName="h2" {...{
        "href": "#measurement-methods",
        "aria-label": "measurement methods permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Firstparty.js relies on two simple methods to collect `}<a parentName="p" {...{
        "href": "/docs/items/events/"
      }}>{`Events`}</a>{`: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`page`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`track`}</code>{`.`}</p>
    <h3 {...{
      "id": "track",
      "style": {
        "position": "relative"
      }
    }}>{`Track`}<a parentName="h3" {...{
        "href": "#track",
        "aria-label": "track permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`track`}</code>{` method collects `}<a parentName="p" {...{
        "href": "/docs/items/events/"
      }}>{`Events`}</a>{` as generated by visitors to your website. You may name your as you see fit, but we
recommend a pattern of two words, starting with a noun and ending with a past-tense verb. For example, you may have
Events such as "Page Viewed", "Form Completed", or "Button Clicked". You may `}<a parentName="p" {...{
        "href": "/docs/items/events/#naming-best-practices"
      }}>{`read more about best-practices when
naming events`}</a>{` on the Events documentation page.`}</p>
    <p>{`Firstparty is different from most other measurement platforms in that the only method that collects data is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`track`}</code>{`.
Additional covenience methods may exist in specific Source SDKs (such as the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`page`}</code>{` method below), but underneath,
every measurement request is a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`track`}</code>{` request.`}</p>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`track`}</code>{` method signature accepts four arguments: the `}<a parentName="p" {...{
        "href": "/docs/items/events/"
      }}>{`Event`}</a>{` name, an optional object of Event Properties, an optional
object of options to contextualize collecting the Event, and an optional callback function run after the Event is
collected.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`firstparty`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`track`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`event`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`properties`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`callback`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <br />
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`track`}</code>{` method has these fields:`}</p>
    <table>
      <tbody>
        <tr>
          <td>
            <code>event</code>
          </td>
          <td>required</td>
          <td>
The required name of the [Event](/docs/items/events/) to collect
          </td>
        </tr>
        <tr>
          <td>
            <code>properties</code>
          </td>
          <td>optional</td>
          <td>
An optional key-value object of properties to collect along with the Event. If the Event name was "Form Completed", one property may be "email_address"
          </td>
        </tr>
        <tr>
          <td>
            <code>options</code>
          </td>
          <td>optional</td>
          <td>
An optional, currently unused object reserved for future optional settings when collecting an Event
          </td>
        </tr>
        <tr>
          <td>
            <code>callback</code>
          </td>
          <td>optional</td>
          <td>
An optional javascript function that will execute after a short amount of time, to allow the Event to be sent to Firstparty
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <p>{`An example `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`track`}</code>{` call might look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`firstparty`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`track`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Form Completed'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Contact Us'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  first_name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Johnny'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  last_name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Rose'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  email`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'johnny@rosebudmotels.com'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3 {...{
      "id": "page",
      "style": {
        "position": "relative"
      }
    }}>{`Page`}<a parentName="h3" {...{
        "href": "#page",
        "aria-label": "page permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`page`}</code>{` method is an extension of Track that automatically sets the `}<a parentName="p" {...{
        "href": "/docs/items/events/"
      }}>{`Event`}</a>{` name to "Page Viewed" and collects the Event.
It is meant to make it easy to collect page views on a website, and collects additional contextual data about the page
at the time of collection.`}</p>
    <p>{`A `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`page`}</code>{` call is added by default in the Firstparty.js snippet provided in your Javascript Source setup screen. You may
modify that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`page`}</code>{` call, or even remove it completely.`}</p>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`page`}</code>{` method signature accepts five optional arguments: the category of the page, the name of the page, an object of
Page Properties, an object of options to contextualize collecting the Page information, and an optional callback function run after
the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`page`}</code>{` data is collected.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`firstparty`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`page`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`category`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`properties`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`callback`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`page`}</code>{` method has these fields:`}</p>
    <table>
      <tbody>
        <tr>
          <td>
            <code>category</code>
          </td>
          <td>optional</td>
          <td>
An optional category of the page. <strong>NOTE</strong>: If the <code>page</code> method only receives one argument, it is assumed that argument
is the <code>name</code>. You must include both a <code>name</code> and <code>category</code> (in that order) to send a <code>category</code>.
          </td>
        </tr>
        <tr>
          <td>
            <code>name</code>
          </td>
          <td>optional</td>
          <td>
A specific name of the page. If nothing is passed, the value of the <code>&lt;title&gt;</code> tag will be used to determine the name.
          </td>
        </tr>
        <tr>
          <td>
            <code>properties</code>
          </td>
          <td>optional</td>
          <td>
An optional key-value object of properties to collect along with the Event.
          </td>
        </tr>
        <tr>
          <td>
            <code>options</code>
          </td>
          <td>optional</td>
          <td>
An optional, currently unused object reserved for future optional settings when collecting an Event
          </td>
        </tr>
        <tr>
          <td>
            <code>callback</code>
          </td>
          <td>optional</td>
          <td>
An optional javascript function that will execute after a short amount of time, to allow the Event to be sent to Firstparty
          </td>
        </tr>
      </tbody>
    </table>
    <p>{`An example `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`page`}</code>{` call might look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`firstparty`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`page`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <br />
    <p>{`A `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`page`}</code>{` call will automatically set several Properties for you based on data from the vistor's browser, unless
specifically overridden:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`firstparty`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`page`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'Contact'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  title`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Contact Us'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  url`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'https://rosebudmotels.com/contact'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  path`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'/contact'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  referrer`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'https://google.com/'`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      